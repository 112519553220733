import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Col, Input, Modal, Row, Upload } from 'antd'
import {
  EditOutlined,
  FileTextOutlined,
  MailOutlined,
  SafetyOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  getOrder,
  getOrderLogs,
  getOrderStepper,
  importOrder,
  uploadAttachments,
  addNotes,
  sendOrderToCustomer,
  manualSendOrderToCustomer,
  verifyOtp,
  sendConditionsCopyToCustomer,
  manualSendConditionsCopyToCustomer
} from 'Redux/actions/order-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import Card from 'Components/Card'
import Stepper from 'Components/Stepper'
import LogsTable from 'Components/LogsTable'
import { PageTitle } from 'Common/styled'

import { isMobile, DATE_FORMAT, BU, buttonActionsMap } from 'Common/constants'
import { formatDate } from 'Common/utils'

const { REACT_APP_API_URL } = process.env

class Order extends Component {
  static propTypes = {
    addNotes: PropTypes.func,
    getOrder: PropTypes.func,
    getOrderLogs: PropTypes.func,
    getOrderStepper: PropTypes.func,
    importOrder: PropTypes.func,
    logs: PropTypes.array,
    manualSendConditionsCopyToCustomer: PropTypes.func,
    manualSendOrderToCustomer: PropTypes.func,
    order: PropTypes.object,
    sendConditionsCopyToCustomer: PropTypes.func,
    sendOrderToCustomer: PropTypes.func,
    stepper: PropTypes.object,
    uploadAttachments: PropTypes.func,
    verifyOtp: PropTypes.func
  }

  state = {
    isLoading: false,
    attachments: [],
    isUploadModalVisible: false,
    isUploadModalLoading: false
  }

  componentDidMount() {
    const { match, history } = this.props
    const { orderNumber } = match.params
    if (orderNumber) {
      this.getOrder(orderNumber)
    } else {
      history.push('/')
    }
  }

  getOrder = async orderNumber => {
    const {
      history,
      order,
      getOrder,
      getOrderLogs,
      getOrderStepper,
      importOrder
    } = this.props
    this.setState({ isLoading: true })
    const res = await getOrder(orderNumber || order.orderNumber)
    if (res.status === 204) return history.push('/')

    await getOrderLogs(res.orderNumber)
    await getOrderStepper(res.orderNumber)
    await importOrder(res.orderNumber, res.storeId, false, false)
    this.setState({ isLoading: false, isUploadModalLoading: false })
  }

  uploadAttachments = async () => {
    const { attachments } = this.state
    const { order, uploadAttachments } = this.props
    this.setState({ isUploadModalLoading: true })
    await uploadAttachments(order.Id, attachments)
    this.closeUploadModal()
    this.getOrder()
  }

  addNotes = async notes => {
    const { order, addNotes } = this.props
    this.setState({ isLoading: true })
    await addNotes(order.Id, notes)
    this.getOrder()
  }

  sendOrderToCustomer = async () => {
    const { order, sendOrderToCustomer } = this.props
    this.setState({ isLoading: true })
    await sendOrderToCustomer(order.Id, order.customer.phoneNumber)
    this.getOrder()
  }

  manualSendOrderToCustomer = async () => {
    const { order, manualSendOrderToCustomer } = this.props
    this.setState({ isLoading: true })
    await manualSendOrderToCustomer(order.Id)
    this.getOrder()
  }

  verifyOtp = async () => {
    const { order, verifyOtp } = this.props
    const otp = document.getElementById('otp').value
    this.setState({ isLoading: true })
    await verifyOtp(order.Id, otp)
    this.getOrder()
  }

  sendConditionsCopyToCustomer = async () => {
    const { order, sendConditionsCopyToCustomer } = this.props
    this.setState({ isLoading: true })
    await sendConditionsCopyToCustomer(
      order.Id,
      order.customer.firstName,
      order.customer.email
    )
    this.getOrder()
  }

  manualSendConditionsCopyToCustomer = async () => {
    const { order, manualSendConditionsCopyToCustomer } = this.props
    this.setState({ isLoading: true })
    await manualSendConditionsCopyToCustomer(order.Id)
    this.getOrder()
  }

  navigateToList = () => {
    const { history } = this.props
    history.goBack()
  }

  openModal = (icon, title, content, okCallback, okText) => {
    const { intl } = this.props
    Modal.confirm({
      icon,
      title,
      content,
      okText: okText || intl.formatMessage({ id: 'order.modal.okText' }),
      cancelText: intl.formatMessage({ id: 'order.modal.cancelText' }),
      onOk: okCallback
    })
  }

  openNotesModal = () => {
    const { intl } = this.props
    const icon = <EditOutlined />
    const title = intl.formatMessage({ id: 'order.notesModal.title' })
    const content = <Input.TextArea id={'notes-input'} />

    const okCallback = () => this.addNotes(document.getElementById('notes-input').value)
    const okText = intl.formatMessage({ id: 'order.notesModal.okText' })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openSmsModal = () => {
    const { intl, order } = this.props
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'order.smsModal.title' })
    const content = (
      <>
        <Input value={order.customer.phoneNumber} />
        <small>{intl.formatMessage({ id: 'order.smsModal.text' })}</small>
      </>
    )

    const okCallback = this.sendOrderToCustomer
    this.openModal(icon, title, content, okCallback)
  }

  openLinkModal = () => {
    const { intl, order } = this.props
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'order.linkModal.title' })
    const content = (
      <>
        <Input value={order.customerUrl} />
        <small>{intl.formatMessage({ id: 'order.linkModal.text' })}</small>
      </>
    )

    const okCallback = this.manualSendOrderToCustomer
    const okText = intl.formatMessage({ id: 'order.linkModal.okText' })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openOtpModal = () => {
    const { intl } = this.props
    const icon = <SafetyOutlined />
    const title = intl.formatMessage({ id: 'order.otpModal.title' })

    const content = (
      <>
        <Input id={'otp'} />
        <small>{intl.formatMessage({ id: 'order.otpModal.text' })}</small>
      </>
    )

    const okCallback = this.verifyOtp
    const okText = intl.formatMessage({ id: 'order.otpModal.okText' })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openEmailModal = () => {
    const { intl, order } = this.props
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'order.emailModal.title' })
    const content = (
      <>
        <Input value={order.customer.email} />
        <small>{intl.formatMessage({ id: 'order.emailModal.text' })}</small>
      </>
    )

    const okCallback = this.sendConditionsCopyToCustomer
    this.openModal(icon, title, content, okCallback)
  }

  openAttachmentsModal = () => {
    const { intl, order } = this.props
    const icon = <FileTextOutlined />
    const title = intl.formatMessage({ id: 'order.attachmentsModal.title' })
    const content = (
      <>
        {order.conditionIds.map(id => (
          <p key={id}>
            <a
              href={`${REACT_APP_API_URL}/customer/order/${order.Id}/condition/${id}?bu=${BU}`}
              target={'_blank'}
              rel={'noopener noreferrer'}>
              {id}
            </a>
          </p>
        ))}
        <small>
          {intl.formatMessage({ id: 'order.attachmentsModal.text' })}
        </small>
      </>
    )

    const okCallback = this.manualSendConditionsCopyToCustomer
    this.openModal(icon, title, content, okCallback)
  }

  addAttachment = file => {
    this.setState(prevState => ({
      attachments: [...prevState.attachments, file]
    }))
    return false
  }

  removeAttachment = file => {
    this.setState(prevState => {
      const index = prevState.attachments.indexOf(file)
      const newAttachments = prevState.attachments.slice()
      newAttachments.splice(index, 1)
      return { attachments: newAttachments }
    })
  }

  openUploadModal = () => this.setState({ isUploadModalVisible: true })
  closeUploadModal = () =>
    this.setState({ isUploadModalVisible: false, attachments: [] })

  renderUploadModal = () => {
    const { attachments, isUploadModalLoading, isUploadModalVisible } =
      this.state
    const { intl } = this.props

    return (
      <Modal
        visible={isUploadModalVisible}
        title={intl.formatMessage({ id: 'order.uploadModal.title' })}
        onCancel={this.closeUploadModal}
        onOk={this.uploadAttachments}
        cancelText={intl.formatMessage({
          id: 'order.modal.cancelText'
        })}
        okText={intl.formatMessage({
          id:
            attachments.length > 0
              ? 'order.uploadModal.okText.withAttachments'
              : 'order.uploadModal.okText.withoutAttachments'
        })}>
        {isUploadModalLoading ? (
          <Loader height={'50px'} />
        ) : (
          <Upload
            fileList={attachments}
            accept={'.pdf'}
            onRemove={this.removeAttachment}
            beforeUpload={this.addAttachment}>
            <Button ghost>
              {intl.formatMessage({ id: 'order.uploadModal.button.upload' })}
            </Button>
          </Upload>
        )}
      </Modal>
    )
  }

  renderOrderCard() {
    const { intl, order } = this.props
    const { fullOrderNumber, orderDate, totalAmount } = order
    return (
      <Col key={'order'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          title={intl.formatMessage({ id: 'order.card.title.order' })}
          highlight={fullOrderNumber}
          data={[
            intl.formatMessage(
              { id: 'order.card.text.date' },
              { date: formatDate(orderDate, DATE_FORMAT) }
            ),
            intl.formatMessage(
              { id: 'order.card.text.totalAmount' },
              { totalAmount }
            )
          ]}
        />
      </Col>
    )
  }

  renderCustomerCard() {
    const { intl, order } = this.props
    const { customer = {} } = order
    return (
      <Col key={'customer'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          title={intl.formatMessage({ id: 'order.card.title.customer' })}
          highlight={`${customer.firstName} ${customer.lastName}`}
          data={[customer.phoneNumber, customer.email]}
        />
      </Col>
    )
  }

  renderStepper() {
    const { stepper } = this.props
    // Pass all the actions mapped as props to Stepper component
    const buttonActions = {}
    Object.values(buttonActionsMap).map(
      action => (buttonActions[action] = () => this[action]())
    )
    return (
      <Col key={'stepper'} md={{ span: 14 }} xs={{ span: 24 }}>
        <Stepper {...stepper} {...buttonActions} />
      </Col>
    )
  }

  render() {
    const { isLoading } = this.state
    const { logs } = this.props
    if (isLoading) return <Loader />

    return (
      <>
        <PageTitle>
          <FormattedMessage id={'order.title'} />
        </PageTitle>
        <Row gutter={[32, 16]}>
          {isMobile
            ? [
              this.renderOrderCard(),
              this.renderCustomerCard(),
              this.renderStepper()
            ]
            : [
              this.renderOrderCard(),
              this.renderStepper(),
              this.renderCustomerCard()
            ]}
        </Row>
        {!isMobile ? <LogsTable data={logs} /> : null}
        {this.renderUploadModal()}
      </>
    )
  }
}
const mapStateToProps = state => ({
  logs: state.order.logs,
  order: state.order.current,
  stepper: state.order.stepper
})
const mapDispatchToProps = {
  getOrder,
  getOrderLogs,
  getOrderStepper,
  importOrder,
  uploadAttachments,
  addNotes,
  sendOrderToCustomer,
  manualSendOrderToCustomer,
  verifyOtp,
  sendConditionsCopyToCustomer,
  manualSendConditionsCopyToCustomer
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Order))
