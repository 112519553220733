import { GET_STORES } from '../action-types'

export const initialState = {
  stores: [],
}

const app = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STORES:
      return { ...state, stores: payload }
    default:
      return state
  }
}

export default app;
